<template>
  <div class="doctorList_container global_full_screen">
    <div class="doctorList_tab">
      <img :src="require('@/assets/images/icon/user_icon.png')" alt="" />
      <span class="global_primary_text_color">{{ $tools.getRoleName() }}:</span>
      <span>{{ $store.state.common.adminInfo.phone }}</span>
    </div>
    <div class="doctorList_meet">
      <div
        class="doctorList_meet_item"
        v-for="(item, index) in config.info"
        :key="index"
      >
        <customButton size="large" class="doctorList_meet_info">
          <div class="doctorList_meet_icon doctorList_meet_area">
            <div class="doctorList_meet_icon_block global_container_center">
              <img
                :src="require(`@/assets/images/icon/${item.icon}.png`)"
                alt=""
              />
            </div>
          </div>
          <div class="doctorList_meet_count doctorList_meet_area">
            {{ getCount(item.key) }}
          </div>
          <div class="doctorList_meet_name doctorList_meet_area">
            {{ item.name }}
          </div>
        </customButton>
      </div>
    </div>
    <div class="doctorList_tab">
      <img :src="require('@/assets/images/icon/user_icon.png')" alt="" />
      <span class="global_primary_text_color"
        >已匹配医生（{{ data.assigned_doctor_count }}）</span
      >
    </div>
    <div class="doctorList_list">
      <listScroll :url="$urls.admin.doctorList">
        <template v-slot:listItem="{ citems }">
          <div class="doctorList_items" @click="inDoctorMeets(citems)">
            <div class="doctorList_doctor_icon global_container_center">
              <div
                class="doctorList_doctor_icon_item global_container_center global_container_img global_primary_background_color"
              >
                <img
                  :src="require(`@/assets/images/icon/doctor_icon.png`)"
                  alt=""
                />
              </div>
            </div>
            <div class="doctorList_doctor_info_item">
              <div class="doctorList_doctor_name">姓名：{{ citems.name }}</div>
              <div class="doctorList_doctor_hospital">
                医院：{{ citems.hospital || "" }}
              </div>
            </div>
            <div class="doctorList_doctor_number_item">
              <div></div>
              <div class="doctorList_doctor_meet_number">
                <span>已分配会议：{{ citems.meet_count }}</span>
                <el-icon> <ArrowRight /></el-icon>
              </div>
            </div>
          </div>
        </template>
      </listScroll>
    </div>
  </div>
</template>
<script>
import { ElIcon } from "element-plus";
import { ArrowRight } from "@element-plus/icons";
import customButton from "@/components/unit/customButton";
import listScroll from "@/components/unit/listScroll";
export default {
  name: "doctorList",
  data() {
    return {
      config: {
        info: [
          {
            name: "总会议",
            key: "can_create_meeting",
            icon: "meet_count",
          },
          {
            name: "已分配会议",
            key: "already_assigned_meeting_count",
            icon: "meet_use",
          },
          {
            name: "待分配会议",
            key: "remaining_meetings_count",
            icon: "meet_remain",
          },
        ],
      },
      data: {
        can_create_meeting: 0,
        already_assigned_meeting_count: 0,
        remaining_meetings_count: 0,
        assigned_doctor_count: 0,
      },
    };
  },
  components: {
    ArrowRight,
    ElIcon,
    customButton,
    listScroll,
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "肺癌挑战赛";
    this.loadData();
  },
  methods: {
    loadData() {
      this.$axios
        .get(this.$urls.admin.meetingCount)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {});
    },
    getCount(key) {
      return this.data[key] || 0;
    },
    inDoctorMeets(data) {
      this.$router.push({
        path: "/doctorMeets",
        query: {
          id: data.id,
        },
      });
    },
  },
};
</script>
<style >
.doctorList_container {
  display: flex;
  flex-direction: column;
  padding: 4vw;
}
.doctorList_tab {
  flex: 4.8vw 0 0;
  margin-bottom: 3.6vw;
  display: flex;
  align-items: center;
  line-height: 4.8vw;
  font-weight: 700;
}
.doctorList_tab img {
  height: 4.8vw;
  width: auto;
  margin-right: 1vw;
}
.doctorList_meet {
  flex: 26.8vw 0 0;
  margin-bottom: 6.8vw;
  display: flex;
  justify-content: space-between;
}
.doctorList_list {
  flex: 1 0 0;
  overflow: hidden;
}
.doctorList_text_mark {
  font-weight: 700;
  font-size: 4vw;
}
.doctorList_meet_item {
  width: 26.8vw;
  height: 100%;
}
.doctorList_meet_area {
  width: 100%;
}
.doctorList_meet_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2vw;
}
.doctorList_meet_icon {
  color: #fff;
  display: flex;
  justify-content: flex-end;
}
.doctorList_meet_name {
  white-space: nowrap;
}
.doctorList_meet_count {
  font-weight: 700;
}
.doctorList_meet_icon_block {
  height: 8.8vw;
  width: 8.8vw;
  background: rgba(233, 233, 233, 0.5);
  border-radius: 4vw;
}
.doctorList_meet_icon_block img {
  width: 4vw;
  height: auto;
}
.doctorList_doctor_icon_item {
  height: 10vw;
  width: 10vw;
  border-radius: 5vw;
  margin-right: 4vw;
}
.doctorList_doctor_icon_item img {
  width: 6.8vw;
  height: auto;
}
.doctorList_items {
  display: flex;
  padding: 4vw;
  background: #f6f6f6;
  border-radius: 2vw;
  min-height: 20vw;
  margin-bottom: 4vw;
}
.doctorList_doctor_info_item {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 3.6vw;
}
.doctorList_doctor_number_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 3.6vw;
}
</style>